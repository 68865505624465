import * as yup from "yup";

export const LoginSchemas = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup.string().min(6).required("Password is required"),
});
export const ChangepassSchemas = yup.object({
  oldpassword: yup.string().required("Old password is required"),
  password: yup.string().min(6).required("Password is required"),
});
export const AddnewsSchemas = yup.object({
  TITLE_ENGLISH: yup.string().required("TITLE ENGLISH is required"),
  TITLE_GERMAN: yup.string().required("TITLE GERMAN is required"),
  TITLE_FRENCH: yup.string().required("TITLE FRENCH is required"),
  TITLE_ITALIAN: yup.string().required("TITLE ITALIAN is required"),
  TITLE_SPANISH: yup.string().required("TITLE SPANISH is required"),
  DESCRIPTION_ENGLISH: yup.string().required("DESCRIPTION ENGLISH is required"),
  DESCRIPTION_GERMAN: yup.string().required("DESCRIPTION GERMAN is required"),
  DESCRIPTION_FRENCH: yup.string().required("DESCRIPTION FRENCH is required"),
  DESCRIPTION_ITALIAN: yup.string().required("DESCRIPTION ITALIAN is required"),
  DESCRIPTION_SPANISH: yup.string().required("DESCRIPTION SPANISH is required"),
  title_image: yup.string().required("Title image is required"),
  // images: yup.string().email().required("images is required"),
});
export const AddCategorySchemas = yup.object({
  ENGLISH: yup.string().required("ENGLISH is required"),
  GERMAN: yup.string().required("GERMAN is required"),
  FRENCH: yup.string().required("FRENCH is required"),
  ITALIAN: yup.string().required("ITALIAN is required"),
  SPANISH: yup.string().required("SPANISH is required"),
  // title_image: yup.string().required("image is required"),
  category_id: yup.array().min(1, "This field is required").of(yup.object()),
});
export const AddfinalproductSchemas = yup.object({
  ENGLISH: yup.string().required("ENGLISH is required"),
  GERMAN: yup.string().required("GERMAN is required"),
  FRENCH: yup.string().required("FRENCH is required"),
  ITALIAN: yup.string().required("ITALIAN is required"),
  SPANISH: yup.string().required("SPANISH is required"),
  // title_image: yup.string().required("image is required"),
  category_id: yup.string().required("Category is required"),
  type_id: yup.string().required("Type is required"),
  subtype_id: yup.string().required("Subtype is required"),
  subcategory_id: yup.string().required("Subcategory is required"),
});
export const AddsubCategorySchemas = yup.object({
  ENGLISH: yup.string().required("ENGLISH is required"),
  GERMAN: yup.string().required("GERMAN is required"),
  FRENCH: yup.string().required("FRENCH is required"),
  ITALIAN: yup.string().required("ITALIAN is required"),
  SPANISH: yup.string().required("SPANISH is required"),
  // title_image: yup.string().required("image is required"),y
  // category_id: yup.string().required("Category is required"),
  category_ids: yup.array().min(1, "This field is required").of(yup.object()),
});

export const AddBrandvalueSchemas = yup.object({
  ENGLISH: yup.string().required("ENGLISH is required"),
  GERMAN: yup.string().required("GERMAN is required"),
  FRENCH: yup.string().required("FRENCH is required"),
  ITALIAN: yup.string().required("ITALIAN is required"),
  SPANISH: yup.string().required("SPANISH is required"),
});
export const SendNewsletterSchemas = yup.object({
  subject: yup.string().required("Subject is required"),
  title: yup.string().required("Title is required"),
  body: yup.string().required("DESCRIPTION is required"),
});
