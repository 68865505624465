import useLoading from "components/Loader/useLoading";
import axios from "../../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
// import { AddnewsSchemas } from "Schemas";
import { useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import Fetchdata from "hooks/Fetchdata";
import { AddsubCategorySchemas } from "Schemas";

const AddSubtypes = () => {
  const { response, isloading } = Fetchdata(endpoints.gettypes, {}, "GET");
  //   console.log(response);
  const initialValues = {
    ENGLISH: "",
    GERMAN: "",
    FRENCH: "",
    ITALIAN: "",
    SPANISH: "",
    title_image: "",
    category_ids: [],
  };

  const [image, setimage] = useState();
  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);

  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.addSubtype,
        {
          ENGLISH: values.ENGLISH,
          GERMAN: values.GERMAN,
          FRENCH: values.FRENCH,
          ITALIAN: values.ITALIAN,
          SPANISH: values.SPANISH,
          image: values.title_image,
          type_id: values?.category_ids?.map((item) => item.value)?.join(","),
        },
        header
      );
      if (res.data.status === 1) {
        navigate("/subtypes");
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      stopLoading();
      //   console.log(res);
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddsubCategorySchemas,
    });

  // const handleimage = async (e, name) => {
  //   const file = e.target.files[0];
  //   const formdata = new FormData();
  //   formdata.append("image", file);
  //   startLoading();
  //   try {
  //     const res = await axios.post(endpoints.upload_image, formdata, header2);
  //     if (res.data.status === 1) {
  //       setFieldValue(name, res.data.data);
  //       setimage(res.data.data);
  //     }
  //     //   console.log(res);
  //     stopLoading();
  //   } catch (error) {
  //     console.log(error);
  //     stopLoading();
  //   }
  // };

  // const handledelete = async () => {
  //   startLoading();
  //   try {
  //     const res = await axios.post(endpoints.delete_image, { img: values.title_image }, header);
  //     //   console.log(res);
  //     if (res.data.status === 1) {
  //       setFieldValue("title_image", "");
  //       setimage("");
  //       toast.success(res.data.message);
  //     } else if (res.data.status === 0) {
  //       toast.error(res.data.message);
  //     }
  //     stopLoading();
  //   } catch (error) {
  //     console.log(error);
  //     stopLoading();
  //   }
  // };

  const options = response?.data?.map((item) => ({
    value: item.id,
    label: item.ENGLISH,
  }));

  const handlecountryChange = (selectedOptions) => {
    setFieldValue(
      "category_ids",
      selectedOptions.map((item) => ({ value: item.value, label: item.label }))
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <Select
            options={options}
            isMulti
            // value={values?.country_of_manufacture?.map((item) => ({
            //   value: item.id || item.value,
            //   label: item.name || item.label,
            // }))}
            styles={{ background: "transparent", zIndex: "100" }}
            onChange={handlecountryChange}
            name="country_of_manufacture"
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false}
          />
          {errors.category_ids
            ? touched.category_ids && <p className="errortag">{errors.category_ids}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ENGLISH"
            onBlur={handleBlur}
            label="English"
            fullWidth
          />
          {errors.ENGLISH ? touched.ENGLISH && <p className="errortag">{errors.ENGLISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="GERMAN"
            onBlur={handleBlur}
            label="German"
            fullWidth
          />
          {errors.GERMAN ? touched.GERMAN && <p className="errortag">{errors.GERMAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="FRENCH"
            onBlur={handleBlur}
            label="French"
            fullWidth
          />
          {errors.FRENCH ? touched.FRENCH && <p className="errortag">{errors.FRENCH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ITALIAN"
            onBlur={handleBlur}
            label="Italian"
            fullWidth
          />
          {errors.ITALIAN ? touched.ITALIAN && <p className="errortag">{errors.ITALIAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="SPANISH"
            onBlur={handleBlur}
            label="Spanish"
            fullWidth
          />
          {errors.SPANISH ? touched.SPANISH && <p className="errortag">{errors.SPANISH}</p> : null}
        </MDBox>
        {/* <MDBox mb={2}>
          <span>Image</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "title_image")}
            name="Image"
            onBlur={handleBlur}
            // label="Title spanish"
            fullWidth
          />
          <div className="d-flex gap-1 mt-2">
            <img src={image} alt="" style={{ width: "200px" }} />
            {values.title_image !== "" && <CloseIcon onClick={() => handledelete()} />}{" "}
          </div>
          {errors.title_image
            ? touched.title_image && <p className="errortag">{errors.title_image}</p>
            : null}
        </MDBox> */}
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default AddSubtypes;
