import useLoading from "components/Loader/useLoading";
import axios from "../../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import Fetchdata from "hooks/Fetchdata";
import { AddfinalproductSchemas } from "Schemas";

const AddsubProduct = () => {
  const [subcat, setsubcat] = useState([]);
  const [typee, settypee] = useState([]);
  const [subtypee, setsubtypee] = useState([]);
  const { response, isloading } = Fetchdata(endpoints.getdatacategories, {}, "GET");
  const { response: finalitem } = Fetchdata(endpoints.getdataspecificitems, {}, "POST");

  const initialValues = {
    ENGLISH: "",
    GERMAN: "",
    FRENCH: "",
    ITALIAN: "",
    SPANISH: "",
    title_image: "",
    type_id: "",
    subtype_id: "",
    subcategory_id: "",
    category_id: "",
    final_item_id: "",
  };

  const [message, setmessage] = useState({ type: "", subtype: "", subcate: "" });
  const [subload, setloading] = useState(false);
  const [typeload, settypeloading] = useState(false);
  const [subtypeload, setsubtypeloading] = useState(false);

  const [image, setimage] = useState();
  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);

  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.addAccessorysubitem,
        {
          ENGLISH: values.ENGLISH,
          GERMAN: values.GERMAN,
          FRENCH: values.FRENCH,
          ITALIAN: values.ITALIAN,
          SPANISH: values.SPANISH,
          image: values.title_image,
          type_id: values.type_id,
          subtype_id: values.subtype_id,
          subcategory_ids: values.subcategory_id,
          category_id: values.category_id,
          final_item_id: values.final_item_id,
        },
        header
      );
      if (res.data.status === 1) {
        navigate("/subfinalproduct");
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      stopLoading();
      // console.log(res);
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddfinalproductSchemas,
    });

  //   const handleimage = async (e, name) => {
  //     const file = e.target.files[0];
  //     const formdata = new FormData();
  //     formdata.append("image", file);
  //     startLoading();
  //     try {
  //       const res = await axios.post(endpoints.upload_image, formdata, header2);
  //       if (res.data.status === 1) {
  //         setFieldValue(name, res.data.data);
  //         setimage(res.data.data);
  //       }
  //       // console.log(res);
  //       stopLoading();
  //     } catch (error) {
  //       console.log(error);
  //       stopLoading();
  //     }
  //   };

  //   const handledelete = async () => {
  //     startLoading();
  //     try {
  //       const res = await axios.post(endpoints.delete_image, { img: values.title_image }, header);
  //       //   console.log(res);
  //       if (res.data.status === 1) {
  //         setFieldValue("title_image", "");
  //         setimage("");
  //         toast.success(res.data.message);
  //       } else if (res.data.status === 0) {
  //         toast.error(res.data.message);
  //       }
  //       stopLoading();
  //     } catch (error) {
  //       console.log(error);
  //       stopLoading();
  //     }
  //   };
  const getsubcat = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        endpoints.getdatasubcategories,
        { category_id: values.category_id },
        header
      );
      // console.log(res);
      if (res.data.status === 1) {
        setsubcat(res.data.data);
        setmessage((prev) => ({ ...prev, subcate: "" }));
      } else if (res.data.status === 0) {
        setmessage((prev) => ({ ...prev, subcate: res.data.message }));
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };
  const gettype = async () => {
    settypeloading(true);
    try {
      const res = await axios.post(
        endpoints.getdatatypes,
        { subcategory_id: values.subcategory_id },
        header
      );
      // console.log(res);
      if (res.data.status === 1) {
        settypee(res.data.data);
        setmessage((prev) => ({ ...prev, type: "" }));
      } else if (res.data.status === 0) {
        setmessage((prev) => ({ ...prev, type: res.data.message }));
      }
      settypeloading(false);
    } catch (error) {
      console.log(error);
      settypeloading(false);
    }
  };
  const getsubtype = async () => {
    setsubtypeloading(true);
    try {
      const res = await axios.post(endpoints.getdatasubtypes, { type_id: values.type_id }, header);
      // console.log(res);
      if (res.data.status === 1) {
        setsubtypee(res.data.data);
        setmessage((prev) => ({ ...prev, subtype: "" }));
      } else if (res.data.status === 0) {
        setmessage((prev) => ({ ...prev, subtype: res.data.message }));
      }
      setsubtypeloading(false);
    } catch (error) {
      console.log(error);
      setsubtypeloading(false);
    }
  };

  useEffect(() => {
    if (values.category_id !== "") {
      getsubcat();
    }
  }, [values.category_id]);

  useEffect(() => {
    if (values.subcategory_id !== "") {
      gettype();
    }
  }, [values.subcategory_id]);

  useEffect(() => {
    if (values.type_id !== "") {
      getsubtype();
    }
  }, [values.type_id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <select
            name={"final_item_id"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.final_item_id}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select Final product
            </option>
            {isloading && <option value="">Loading.....</option>}
            {finalitem?.data?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.English}
                </option>
              );
            })}
          </select>
          {errors.category_id
            ? touched.category_id && <p className="errortag">{errors.category_id}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <select
            name={"category_id"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.category_id}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select Category
            </option>
            {isloading && <option value="">Loading.....</option>}
            {response?.data?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.English}
                </option>
              );
            })}
          </select>
          {errors.category_id
            ? touched.category_id && <p className="errortag">{errors.category_id}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <select
            name={"subcategory_id"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.subcategory_id}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select Sub Category
            </option>
            {subload && <option value="">Loading.....</option>}
            {message.subcate && <option value="">{message.subcate}</option>}
            {subcat?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.English}
                </option>
              );
            })}
          </select>
          {errors.subcategory_id
            ? touched.subcategory_id && <p className="errortag">{errors.subcategory_id}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <select
            name={"type_id"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.type_id}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select Type
            </option>
            {typeload && <option value="">Loading.....</option>}
            {message.type && <option value="">{message.type}</option>}
            {typee?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.English}
                </option>
              );
            })}
          </select>
          {errors.type_id ? touched.type_id && <p className="errortag">{errors.type_id}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <select
            name={"subtype_id"}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.subtype_id}
            className="selecttag"
          >
            <option value="" selected disabled>
              Select Sub Type
            </option>
            {subtypeload && <option value="">Loading.....</option>}
            {message.subtype && <option value="">{message.subtype}</option>}
            {subtypee?.map((item) => {
              return (
                <option value={item.id} key={item.id}>
                  {item.English}
                </option>
              );
            })}
          </select>
          {errors.subtype_id
            ? touched.subtype_id && <p className="errortag">{errors.subtype_id}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ENGLISH"
            onBlur={handleBlur}
            label="English"
            fullWidth
          />
          {errors.ENGLISH ? touched.ENGLISH && <p className="errortag">{errors.ENGLISH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="GERMAN"
            onBlur={handleBlur}
            label="German"
            fullWidth
          />
          {errors.GERMAN ? touched.GERMAN && <p className="errortag">{errors.GERMAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="FRENCH"
            onBlur={handleBlur}
            label="French"
            fullWidth
          />
          {errors.FRENCH ? touched.FRENCH && <p className="errortag">{errors.FRENCH}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="ITALIAN"
            onBlur={handleBlur}
            label="Italian"
            fullWidth
          />
          {errors.ITALIAN ? touched.ITALIAN && <p className="errortag">{errors.ITALIAN}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="SPANISH"
            onBlur={handleBlur}
            label="Spanish"
            fullWidth
          />
          {errors.SPANISH ? touched.SPANISH && <p className="errortag">{errors.SPANISH}</p> : null}
        </MDBox>
        {/* <MDBox mb={2}>
          <span>Image</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "title_image")}
            name="Image"
            onBlur={handleBlur}
            // label="Title spanish"
            fullWidth
          />
          <div className="d-flex gap-1 mt-2">
            <img src={image} alt="" style={{ width: "200px" }} />
            {values.title_image !== "" && <CloseIcon onClick={() => handledelete()} />}{" "}
          </div>
          {errors.title_image
            ? touched.title_image && <p className="errortag">{errors.title_image}</p>
            : null}
        </MDBox> */}
        <MDBox mt={4} mb={1}>
          <MDButton disabled={isloading} type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default AddsubProduct;
