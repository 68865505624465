import { Card, keyframes } from "@mui/material";
import Loader from "components/Loader/Loader";
import Simpleloader from "components/Loader/Simpleloader";
import useLoading from "components/Loader/useLoading";
import MDBox from "components/MDBox";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Fetchdata from "hooks/Fetchdata";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../../../../Schemas/Apiurl";
import { Headers } from "hooks/Fetchdata";
import { toast } from "react-toastify";
import Deletepopup from "components/Modals/Deletepopup";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import usePagination from "hooks/usePagination";
import MDInput from "components/MDInput";

const BrandSubvalue = () => {
  const [keyword, setkeyword] = useState("");
  const [show, setShow] = useState({ id: null, isOpen: false });
  const { currentPage, PaginationUI, setCurrentPage } = usePagination();
  const { response, isloading, getdata } = Fetchdata(
    endpoints.getsubbrandvalues,
    { page: currentPage, search: keyword },
    "POST"
  );

  useEffect(() => {
    getdata();
  }, [currentPage]);
  const { loading, startLoading, stopLoading } = useLoading();

  //   console.log(response);
  const navigate = useNavigate();
  const header = Headers();

  const handlestatuschange = async (item) => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.togglesubbrandvalue,
        { id: item.id, action: item.active === "1" ? "0" : "1" },
        header
      );
      stopLoading();
      if (res.data.status === 1) {
        getdata();
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      // console.log(res);
    } catch (error) {
      stopLoading();
      console.log(error);
    }
  };

  const debounceDelay = 500;
  useEffect(() => {
    let timeroutid;
    if (keyword) {
      if (timeroutid) {
        clearTimeout(timeroutid);
      }
      timeroutid = setTimeout(() => {
        getdata();
        if (currentPage > 1) {
          setCurrentPage(1);
        }
      }, debounceDelay);
    } else if (keyword == "") {
      getdata();
    }
    return () => {
      if (timeroutid) {
        clearTimeout(timeroutid);
      }
    };
  }, [keyword]);

  const handledelete = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.softdeletebytype,
        { id: show?.id?.id, type: "sub_brand_values", value: 1 },
        header
      );
      //   console.log(res);
      if (res.data.status === 1) {
        setShow({ isOpen: false });
        getdata();
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  return (
    <DashboardLayout>
      <Deletepopup
        show={show}
        handleClose={() => setShow({ isOpen: false })}
        handledelete={handledelete}
        isimageshow={false}
        name={show?.id?.ENGLISH}
        setShow={setShow}
      />
      <DashboardNavbar />
      <MDBox mt={1}>
        <div className="d-flex align-items-center justify-content-between flex-wrap gap-1 mb-3">
          <MDInput
            type="text"
            onChange={(e) => setkeyword(e.target.value)}
            name="search"
            label="Search"
          />
          <button
            onClick={() => navigate("/brandsubvalue/Addbrandsubvalue")}
            className="createbrandbtn"
            style={{ width: "180px", height: "45px" }}
          >
            Add Brand Value
          </button>
        </div>
        {isloading ? (
          <Simpleloader style={{ height: "300px" }} />
        ) : (
          <>
            {response?.pagination?.total_pages === 0 ? (
              <h1 className="text-center mt-3">{response?.message}</h1>
            ) : (
              <Card>
                <Table responsive="xxl" className="vouchertable mt-3">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Eng Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response?.data?.map((item, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="hover-color-change">{index + 1}</td>
                          <td className="hover-color-change">{item.ENGLISH}</td>
                          <td>
                            <input
                              className="checkboxx"
                              onChange={() => handlestatuschange(item)}
                              type="checkbox"
                              checked={item.active === "1" ? true : false}
                            />
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                navigate("/brandsubvalue/Editbrandsubvalue", {
                                  state: { item },
                                })
                              }
                              className="createbrandbtn"
                            >
                              <EditIcon color="#ffffff" />
                            </button>{" "}
                            <button
                              onClick={() => setShow({ id: item, isOpen: true })}
                              className="createbrandbtn"
                            >
                              <Delete color="#ffffff" />
                            </button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            )}
          </>
        )}
        <PaginationUI response={response} />
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default BrandSubvalue;
