import React, { useState } from "react";

const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const PaginationUI = ({ response }) => {
    const totalPages = response?.pagination?.total_pages || 0;
    return (
      totalPages > 0 && (
        <div className="d-flex align-items-center justify-content-center gap-1 mt-3 mb-3">
          <button
            className="createbrandbtn"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          >
            Prev
          </button>
          <span>{currentPage}</span>
          <button
            className="createbrandbtn"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          >
            Next
          </button>
        </div>
      )
    );
  };

  return { currentPage, setCurrentPage, PaginationUI };
};

export default usePagination;
