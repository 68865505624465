import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import "./Fashioncategory.css";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import Fetchdata, { Headers } from "../../hooks/Fetchdata";
import Simpleloader from "components/Loader/Simpleloader";
import { Table } from "react-bootstrap";
import { Delete } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";
import Deletepopup from "components/Modals/Deletepopup";
import { useEffect, useState } from "react";
import axios from "../../Schemas/Apiurl";
import { endpoints } from "../../endpoints.js";
import useLoading from "components/Loader/useLoading";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";
import usePagination from "hooks/usePagination";
import MDInput from "components/MDInput";

const Fashioncategory = () => {
  const [keyword, setkeyword] = useState("");
  const [show, setShow] = useState({ id: null, isOpen: false });
  const { currentPage, PaginationUI, setCurrentPage } = usePagination();
  const { response, isloading, getdata } = Fetchdata(
    endpoints.getfashioncategories,
    { page: currentPage, search: keyword },
    "POST"
  );

  useEffect(() => {
    getdata();
  }, [currentPage]);
  const { loading, startLoading, stopLoading } = useLoading();

  // console.log(response, "ggsg");
  const navigate = useNavigate();
  const header = Headers();

  const debounceDelay = 500;
  useEffect(() => {
    let timeroutid;
    if (keyword) {
      if (timeroutid) {
        clearTimeout(timeroutid);
      }
      timeroutid = setTimeout(() => {
        getdata();
        if (currentPage > 1) {
          setCurrentPage(1);
        }
      }, debounceDelay);
    } else if (keyword == "") {
      getdata();
    }
    return () => {
      if (timeroutid) {
        clearTimeout(timeroutid);
      }
    };
  }, [keyword]);

  const handledelete = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.deletefashioncategory,
        { id: show?.id?.id, type: "fashion_category", value: 1 },
        header
      );
      //   console.log(res);
      if (res.data.status === 1) {
        setShow({ isOpen: false });
        getdata();
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  return (
    <DashboardLayout>
      <Deletepopup
        show={show}
        handleClose={() => setShow({ isOpen: false })}
        handledelete={handledelete}
        isimageshow={true}
        name={show?.id?.ENGLISH}
        imgg={show?.id?.image}
        setShow={setShow}
      />
      <DashboardNavbar />
      <MDBox mt={1}>
        <div className="d-flex align-items-center flex-wrap justify-content-between mb-3">
          <MDInput
            type="text"
            onChange={(e) => setkeyword(e.target.value)}
            name="search"
            label="Search"
          />
          <button
            onClick={() => navigate("/fashioncategory/Addfashioncategory")}
            className="createbrandbtn"
            style={{ width: "130px", height: "45px" }}
          >
            Add Fashion
          </button>
        </div>
        {isloading ? (
          <Simpleloader style={{ height: "300px" }} />
        ) : (
          <>
            {response?.pagination?.total_pages === 0 ? (
              <h1 className="text-center mt-3">{response?.message}</h1>
            ) : (
              <Card>
                <Table responsive="xxl" className="vouchertable mt-3">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      {/* <th>Image</th> */}
                      <th>Eng Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response?.data?.map((item, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="hover-color-change">
                            {10 * (currentPage - 1) + index + 1}
                          </td>{" "}
                          {/* <td className="hover-color-change">
                            <img
                              src={item.image}
                              alt=""
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </td> */}
                          <td className="hover-color-change">{item.ENGLISH}</td>
                          <td>
                            {/* <button
                              onClick={() =>
                                navigate("/companion/companionDetail", {
                                  state: { item },
                                })
                              }
                              className="createbrandbtn"
                            >
                              <RemoveRedEyeTwoTone color="#ffffff" />
                            </button>{" "} */}
                            <button
                              onClick={() =>
                                navigate("/fashioncategory/Editfashioncategory", {
                                  state: { item },
                                })
                              }
                              className="createbrandbtn"
                            >
                              <EditIcon color="#ffffff" />
                            </button>{" "}
                            <button
                              onClick={() => setShow({ id: item, isOpen: true })}
                              className="createbrandbtn"
                            >
                              <Delete color="#ffffff" />
                            </button>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            )}
          </>
        )}
        <PaginationUI response={response} />
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default Fashioncategory;
